<template>
  <div class="background-login">
    <div class="auth-wrapper auth-v1 px-2">
      <div class="logo-trust">
        <b-img
          fluid
          :src="imgUrl"
          alt="Logo Trust"
        />
      </div>
      <div class="text-trust">
        <h2 class="brand-text text-light">
          T R U S T
        </h2>
        <h2 class="brand-text-2 text-light">
          (<i>Treasury Human Resource System</i>)
        </h2>
      </div>
      <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0 rounded-lg card-shadow">
          <div class="user-image">
            <b-avatar
              class="mx-auto"
              size="100"
              variant="light-primary"
              :src="userImgUrl"
            />
          </div>
          <b-card-text class="mb-0">
            Silahkan login
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-1"
              @submit.prevent
            >

              <!-- Username -->
              <b-form-group
                label="Username"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Login
              </b-button>
            </b-form>
          </validation-observer>

        </b-card>

      <!-- /Login v1 -->

      </div>
      <div class="auth-footer fixed-bottom">
        <b-link
          href="https://tim-inovasi-tik.setditjen-djpb.net/"
          class="text-white"
        >
          2021 © Tim Inovasi TIK - Sekretariat Ditjen Perbendaharaan.
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BImg, BForm, BButton, BAvatar,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import sha1 from 'crypto-js/sha1'
import md5 from 'crypto-js/md5'
import { mapGetters, mapState } from 'vuex'
import store from '@/store/index'
// import debounce from '@/utils/debounce'

export default {
  components: {
    BLink,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BImg,
    BAvatar,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      // username: '',
      sideImg: require('@/assets/images/pages/trust_new.svg'),
      userImgDefault: require('@/assets/images/pages/placeholder.jpg'),
      timeout: null,
      debouncedUsername: '',
      // validation rulesimport store from '@/store/index'
      required,
    }
  },
  computed: {
    ...mapState(['usersPbn']),
    ...mapGetters(['thisUsers', 'isLoginPbn', 'isPegawaiPbn', 'isAdminPbn', 'isPengelolaPbn', 'isAuthenticated']),
    hashPassword() {
      const md5Hash = md5(`${this.password}`)
      const sha1Hash = sha1(md5Hash.toString())
      return sha1Hash
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/trust_new.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    username: {
      get() {
        return this.debouncedUsername
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.debouncedUsername = val
        }, 300)
      },
    },
    userImgUrl() {
      const userImg = `https://foto.setditjen-djpb.net/foto-username/${this.username}`
      if (typeof userImg === 'undefined' || userImg === null || this.username === '') {
        return this.userImgDefault
      }
      return userImg
    },
  },
  watch: {
    isAuthenticated() {
      console.log('Login isAuthenticated changed', this.isAuthenticated)
    },
    isLoginPbn() {
      console.log('Login isLoginPbn changed', this.isLoginPbn)
    },
    isPegawaiPbn() {
      console.log('Login isPegawaiPbn changed', this.isPegawaiPbn)
    },
  },
  beforeCreate() {
    //
  },
  mounted() {
    this.$nextTick(() => {
    //
    })
  },
  methods: {
    initSession() {
      this.$store.dispatch('getSession', { self: this })
    },
    createSession() {
      if (!this.isAuthenticated) {
        this.$store.dispatch('postSession', { self: this })
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Login Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.userLogin()
        }
      })
    },
    userLogin() {
      this.$store
        .dispatch('checkUser', `(uName=${this.username}) AND (uPass=${this.hashPassword}) AND (uActive=y)`)
        .then(() => {
          console.log('isAdminPbn', this.isAdminPbn)
          this.$store.dispatch('getPegawaiFoto', `(nip=${this.thisUsers.uNip})`).then(() => {
            if (this.isLoginPbn && this.isPegawaiPbn) {
              this.$router.push('/')
              console.log('usersPbn', this.thisUsers)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.thisUsers.error.message,
                  icon: 'EditIcon',
                  variant: 'error',
                },
              })
            }
          })
        })
        .catch(error => {
          this.errors = error.response.data.message
        })
        .finally(() => {

        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
